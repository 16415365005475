import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { useTranslations } from "../hooks/translations";
import { REQUIRED, EMAIL } from "../utils/validators";
import SEO from "../components/seo";
import Form from "../components/form";
import FormInput from "../components/form-input";
import Button from "../components/button";
import LocalizedLink from "../components/localized-link";
import LoginContext from "../login-context";
import withLogin from "../components/hoc/login";

import styles from "./login.module.scss";

export const query = graphql`
  query LoginQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        login
      }
      form
      ...LayoutTranslations
    }
  }
`;

const FIELDS = {
  email: {
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "emailRequired"
      },
      {
        validate: email => !email || EMAIL(email),
        message: "emailFormat"
      }
    ]
  },
  password: {
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "passwordRequired"
      }
    ]
  }
};

const LoginPage = ({ location }) => {
  const t = useTranslations("pages.login");

  const { email, password, submit, forgotPassword, errors } = useTranslations("login");

  const { heading, message } = location.state || {};

  const [submitting, setSubmitting] = useState(false);

  const login = useContext(LoginContext);

  const submitForm = useCallback(
    formData => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      login(formData).finally(() => {
        setSubmitting(false);
      });
    },
    [submitting, setSubmitting]
  );

  return (
    <section>
      <div className="bp-container">
        <SEO title={t.title} description={t.metaDescription} robots="noindex" />
        <h1>{heading || t.defaultHeading}</h1>
        <p className="bp-subheading">{message}</p>

        <Form fields={FIELDS} onSubmit={submitForm} errorMessages={errors}>
          {({ setFieldValue, fields }) => (
            <>
              <FormInput
                id="email"
                label={email}
                type="email"
                field={fields.email}
                onChange={setFieldValue}
                autoFocus
              />

              <label htmlFor="password" className="bp-between">
                {password}

                <LocalizedLink
                  to="/forgot-password"
                  state={{ email: fields.email.value }}
                  className={`bp-link ${styles.forgotPassword}`}
                >
                  {forgotPassword}
                </LocalizedLink>
              </label>
              <input
                type="password"
                placeholder={password}
                id="password"
                name="password"
                value={fields.password.value}
                onChange={e => setFieldValue("password", fields.password, e.target.value)}
                className={fields.password.error ? "bp-error" : null}
              />
              {fields.password.error && (
                <div className={`bp-error-message ${styles.passwordErrorMessage}`}>
                  {fields.password.error}
                </div>
              )}

              <Button type="submit" className="bp-standard bp-submit-button" busy={submitting}>
                {submit}
              </Button>
            </>
          )}
        </Form>
      </div>
    </section>
  );
};

LoginPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      heading: PropTypes.string,
      message: PropTypes.string
    })
  }).isRequired
};

export default withLogin(LoginPage);
